import {
  ADD_GOOD,
  CLEAN_GOODS,
  CHANGE_FORM,
  CHANGE_MULTIPLIER,
  DELETE_GOOD,
  REMOVE_GOOD,
} from "./../actionTypes";

export const REDUCER_VERSION = "0.1.0";

const initialState = {
  spb: {
    goods: {},
    form: {},
    multiplier: 100,
    total: 0,
    totalPriceShow: 0,
    totalPrice: 0,
  },
  moscow: {
    goods: {},
    form: {},
    multiplier: 100,
    total: 0,
    totalPriceShow: 0,
    totalPrice: 0,
  },
  version: REDUCER_VERSION,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_GOOD: {
      const good = action.payload;
      let amount = state[action.city].goods[good.id]?.amount || 0;
      let new_goods = {
        ...state[action.city].goods,
        [good.id]: {
          ...good,
          amount: amount + 1,
        },
      };
      const totalPrice = totalPriceFromState(new_goods);
      let returnedState = {};
      returnedState[action.city] = {
        ...state[action.city],
        goods: new_goods,
        total: totalFromState(new_goods),
        totalPrice: totalPrice,
        totalPriceShow: (
          (totalPrice * (state.multiplier || 100)) /
          100
        ).toFixed(2),
      };
      return {
        ...state,
        ...returnedState,
      };
    }
    case REMOVE_GOOD: {
      if (state[action.city].total === 0) {
        return state;
      }
      const good = action.payload;
      let amount = state[action.city].goods[good.id]?.amount - 1 || 0;
      let new_goods = state[action.city].goods;
      if (amount === 0) {
        delete new_goods[good.id];
      } else {
        new_goods[good.id] = {
          ...new_goods[good.id],
          amount: amount,
        };
      }
      const totalPrice = totalPriceFromState(new_goods);
      let returnedState = {};
      returnedState[action.city] = {
        ...state[action.city],
        goods: {
          ...new_goods,
        },
        total: totalFromState(new_goods),
        totalPrice: totalPrice,
        totalPriceShow: (
          (totalPrice * (state.multiplier || 100)) /
          100
        ).toFixed(2),
      };
      return {
        ...state,
        ...returnedState,
      };
    }
    case DELETE_GOOD: {
      let new_goods = state[action.city].goods;
      const good = action.payload;
      delete new_goods[good.id];
      const totalPrice = totalPriceFromState(new_goods);
      let returnedState = {};
      returnedState[action.city] = {
        ...state[action.city],
        goods: {
          ...new_goods,
        },
        total: totalFromState(new_goods),
        totalPrice: totalPrice,
        totalPriceShow: (
          (totalPrice * (state.multiplier || 100)) /
          100
        ).toFixed(2),
      };
      return {
        ...state,
        ...returnedState,
      };
    }
    case CHANGE_FORM: {
      let returnedState = {};
      returnedState[action.city] = {
        ...state[action.city],
        form: action.payload,
      };
      return {
        ...state,
        ...returnedState,
      };
    }
    case CLEAN_GOODS: {
      return initialState;
    }
    case CHANGE_MULTIPLIER: {
      let returnedState = {};
      returnedState[action.city] = {
        ...state[action.city],
        multiplier: action.payload,
        totalPriceShow: (
          (state.totalPrice * (action.payload || 100)) /
          100
        ).toFixed(2),
      };
      return {
        ...state,
        ...returnedState,
      };
    }
    default:
      return state;
  }
}

let totalFromState = (goods) => {
  return Object.values(goods).reduce((ret, good) => {
    return ret + good.amount;
  }, 0);
};

let totalPriceFromState = (goods) => {
  return Object.values(goods).reduce((ret, good) => {
    return ret + good.amount * good.price;
  }, 0);
};
