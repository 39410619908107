import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import "./style.sass";

class Consent extends Component {
  render() {
    const cookieName = "subzeroConsent";

    if (getCookieConsentValue(cookieName)) return null;

    return (
      <CookieConsent
        cookieName={cookieName}
        buttonText={this.props.t("texts.consent.agree")}
      >
        <Trans
          i18nKey="texts.consent.banner"
          components={[
            <Link
              to={{
                pathname: "/privacy-policy",
                search: this.props.location.search,
              }}
            ></Link>,
          ]}
        />
      </CookieConsent>
    );
  }
}

export default withTranslation()(withRouter(Consent));
