import React, { Component } from "react";
import { withRouter } from "react-router";
import { Trans, withTranslation } from "react-i18next";
import "./style.sass";

class PrivacyPolicyScreen extends Component {
  render() {
    return (
      <div className="privacy-policy-screen__holder">
        <div className="privacy-policy-screen__container">
          <div className="privacy-policy-screen__content">
            <h2 className="privacy-policy-screen__headers">
              <Trans i18nKey="texts.consent.page.text1" />
            </h2>
            <div className="privacy-policy-screen__info">
              <Trans i18nKey="texts.consent.page.text2" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(PrivacyPolicyScreen));
