import React, { Component } from "react";
import { withRouter } from "react-router";
import { withAxios } from "react-axios";
import { Provider } from "react-redux";
import { Trans, withTranslation } from "react-i18next";
import { Component as Category } from "../../components/Category";
import { Component as CategoryMenu } from "../../components/CategoryMenu";
import "./style.sass";

class ShopOfferScreen extends Component {
  state = {
    categories: [],
  };

  componentDidMount() {
    this.props.axios.defaults.baseURL = process.env.REACT_APP_API_URL_SPB;

    this.props.axios.get("/goods").then((result) => {
      this.setState({
        categories: result.data.filter(
          (category) => category.goods && category.goods.length !== 0
        ),
      });
    });
  }

  render() {
    const categories = this.state.categories || [];

    const isLoading =
      categories.length === 0 ? (
        <div>
          <Trans i18nKey="shop.loading">Loading...</Trans>
        </div>
      ) : null;

    return (
      <div className="shop-screen__holder">
        <CategoryMenu categories={categories} city={this.props.city} />
        <div className="shop-screen__container">
          {isLoading}
          {categories.map((category) => (
            <Category
              key={category.id}
              category={category}
              city={this.props.city}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(withAxios(ShopOfferScreen)));
