import React, { Component } from "react";
import { withRouter } from "react-router";
import { Trans, withTranslation } from "react-i18next";
import { Component as FranchiseForm } from "./../../components/FranchiseForm";
import "./style.sass";

class FranchiseScreen extends Component {
  state = {
    isOpen: false,
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div className="franchise-screen__holder">
        <FranchiseForm open={this.state.isOpen} toggle={this.toggleForm} />
        <div
          className="franchise-screen__container"
          style={{
            backgroundImage: `url("https://subzerosushi.ru/static/images/franchise/franchise-001.png")`,
          }}
        >
          <div className="franchise-screen__content">
            <div className="franchise-screen__text-holder">
              <h2 className="franchise-screen__headers">
                <Trans i18nKey="texts.franchise.header1" />
              </h2>
              <div className="franchise-screen__info-holder">
                <div className="franchise-screen__info franchise-screen__info-round">
                  <span>
                    <Trans i18nKey="texts.franchise.text1" />
                  </span>
                  <div
                    className="franchise-screen__download"
                    onClick={this.toggleForm}
                  >
                    <Trans i18nKey="texts.franchise.download" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="franchise-screen__container"
          style={{
            backgroundImage: `url("https://subzerosushi.ru/static/images/franchise/franchise-002.png")`,
          }}
        >
          <div className="franchise-screen__content">
            <div className="franchise-screen__text-holder">
              <h2 className="franchise-screen__headers">
                <Trans i18nKey="texts.franchise.header2" />
              </h2>
              <div className="franchise-screen__info-holder">
                <div className="franchise-screen__info franchise-screen__info-round">
                  <span>
                    <Trans i18nKey="texts.franchise.text2" />
                  </span>
                </div>
                <div className="franchise-screen__info franchise-screen__info-round">
                  <span>
                    <Trans i18nKey="texts.franchise.text3" />
                  </span>
                </div>
                <div className="franchise-screen__info franchise-screen__info-round">
                  <span>
                    <Trans i18nKey="texts.franchise.text4" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="franchise-screen__container"
          style={{
            backgroundImage: `url("https://subzerosushi.ru/static/images/franchise/franchise-003.png")`,
          }}
        >
          <div className="franchise-screen__content">
            <div className="franchise-screen__text-holder">
              <h2 className="franchise-screen__headers">
                <Trans i18nKey="texts.franchise.header3" />
              </h2>
              <div className="franchise-screen__info-holder">
                <div className="franchise-screen__info franchise-screen__info-square">
                  <div className="franchise-screen__info-row">
                    <span>
                      <Trans i18nKey="texts.franchise.text5" />
                    </span>
                  </div>
                  <div className="franchise-screen__info-row">
                    <span>
                      <Trans i18nKey="texts.franchise.text6" />
                    </span>
                    <span>
                      <Trans i18nKey="texts.franchise.text7" />
                    </span>
                  </div>
                </div>
                <div className="franchise-screen__info franchise-screen__info-square">
                  <div className="franchise-screen__info-row">
                    <span>
                      <Trans i18nKey="texts.franchise.text8" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="franchise-screen__info-holder">
                <div className="franchise-screen__info franchise-screen__info-square">
                  <div className="franchise-screen__info-row">
                    <span>
                      <Trans i18nKey="texts.franchise.text9" />
                    </span>
                  </div>
                </div>
                <div className="franchise-screen__info franchise-screen__info-square">
                  <div className="franchise-screen__info-row">
                    <span>
                      <Trans i18nKey="texts.franchise.text10" />
                    </span>
                  </div>
                </div>
                <div className="franchise-screen__info franchise-screen__info-square">
                  <div className="franchise-screen__info-row">
                    <span>
                      <Trans i18nKey="texts.franchise.text11" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="franchise-screen__info-holder">
                <span>
                  <Trans i18nKey="texts.franchise.text12" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="franchise-screen__container franchise-screen__container--mobile-hide"
          style={{
            backgroundImage: `url("https://subzerosushi.ru/static/images/franchise/franchise-004.png")`,
          }}
        >
          <div className="franchise-screen__content">
            <div className="franchise-screen__text-holder">
              <h2 className="franchise-screen__headers">
                <Trans i18nKey="texts.franchise.header4" />
              </h2>
              <div className="franchise-screen__info-holder">
                <div className="franchise-screen__info franchise-screen__info-special">
                  <span>
                    <Trans i18nKey="texts.franchise.text1" />
                  </span>
                  <div
                    className="franchise-screen__download"
                    onClick={this.toggleForm}
                  >
                    <Trans i18nKey="texts.franchise.download" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="franchise-screen__container franchise-screen__container--desktop-hide franchise-screen__container--special"
          style={{
            backgroundImage: `url("https://subzerosushi.ru/static/images/franchise/franchise-005.png")`,
          }}
        >
          <div className="franchise-screen__content">
            <div className="franchise-screen__text-holder">
              <img
                src="https://subzerosushi.ru/static/images/franchise/franchise-005.png"
                alt=""
              />
              <h2 className="franchise-screen__headers">
                <Trans i18nKey="texts.franchise.header4" />
              </h2>
            </div>
          </div>
        </div>
        <div className="franchise-screen__container franchise-screen__container--desktop-hide franchise-screen__container--special">
          <div className="franchise-screen__content">
            <div className="franchise-screen__text-holder">
              <div className="franchise-screen__info-holder">
                <div className="franchise-screen__info franchise-screen__info-special">
                  <span>
                    <Trans i18nKey="texts.franchise.text1" />
                  </span>
                  <div
                    className="franchise-screen__download"
                    onClick={this.toggleForm}
                  >
                    <Trans i18nKey="texts.franchise.download" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(FranchiseScreen));
