import React from "react";
import ReactDOM from "react-dom";
import "./index.sass";
import "./app/utils/I18n";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import Config from "./config";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-K493P2T",
};

TagManager.initialize(tagManagerArgs);

Config();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
