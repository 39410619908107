import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Trans, withTranslation } from 'react-i18next';
import { Component as Place } from './../../components/Place';
// import { ReactComponent as Instagram } from "./../../components/Menu/Instagram.svg";
// import { ReactComponent as Facebook } from "./../../components/Menu/Facebook.svg";
import Delimiter from './Delimiter.svg';
import './style.sass';

class ContactsScreen extends Component {
  state = {
    cities: {
      'saint-petersburg': ['subzero', 'subzero-2', 'bar', 'market', 'market2'],
      moscow: ['subzero'],
    },
    city: '',
  };

  render() {
    const places = this.state.cities['saint-petersburg'] || [];

    return (
      <div className='contacts-screen__holder'>
        <div className='contacts-screen__container'>
          <div className='contacts-screen__list'>
            <h2 className='contacts-screen__headers'>
              <Trans i18nKey='common.contacts'>Contacts</Trans>
            </h2>
            <div className='contacts-screen__info'>
              <Trans i18nKey='texts.contacts-email'>
                For cooperation please mail
              </Trans>
              &mdash;&nbsp;
              <a href='mailto:subzerosushi@gmail.com'>subzerosushi@gmail.com</a>
            </div>
            <div
              className='contacts-screen__divider'
              style={{ backgroundImage: `url('${Delimiter}')` }}
            />
            {/*
              <div className="contacts-screen__social-links">
              <Trans i18nKey="texts.follow-us">Follow us</Trans>
              <a
                href="https://www.instagram.com/subzerosushi/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.toggleMenu}
              >
                <Instagram />
              </a>
              <a
                href="https://www.facebook.com/subzeroru"
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.toggleMenu}
              >
                <Facebook />
              </a>
            </div>
            */}
            <div
              className='contacts-screen__divider'
              style={{ backgroundImage: `url('${Delimiter}')` }}
            />
            <div className='contacts-screen__city'>
              <Trans i18nKey='common.spb'>Saint Petersburg</Trans>
            </div>
            {places.map((place) => (
              <Place
                key={`${'saint-petersburg'}:${place}`}
                city={'saint-petersburg'}
                place={place}
                showMap={true}
              />
            ))}
            <div
              className='contacts-screen__divider'
              style={{ backgroundImage: `url('${Delimiter}')` }}
            />
            <div className='contacts-screen__city'>
              <Trans i18nKey='common.moscow'>Moscow</Trans>
            </div>
            {places.map((place) => (
              <Place
                key={`${'moscow'}:${place}`}
                city={'moscow'}
                place={place}
                showMap={true}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ContactsScreen));
