import React, { Component } from "react";
import { withRouter } from "react-router";
import { Trans, withTranslation } from "react-i18next";
import "./style.sass";

class ShopClosed extends Component {
  render() {
    return (
      <div>
        <div className="shop-screen__holder">
          <div className="shop-screen__container">
            <h3>
              К сожалению мы временно приостанавливаем деятельность нашей
              доставки.
            </h3>
            <p>
              Самовывоз из ресторанов по-прежнему доступен.
              <br />
              Адреса ресторанов:
            </p>
            <h3>Subzero</h3>
            <p>
              <br />
              Рубинштейна, 38
              <br />
              +7 812 907 64 38
            </p>
            <hr />
            <h3>Subzero II</h3>
            <p>
              Академика Павлова, 5
              <br />
              +7 812 927 64 38
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ShopClosed));
