import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// import ReactPixel from "react-facebook-pixel";
import { Component as Index } from "./screens/Index";
import { Component as City } from "./screens/City";
import { Component as Place } from "./screens/Place";
import { Component as Contacts } from "./screens/Contacts";
import { Component as Catering } from "./screens/Catering";
import { Component as Franchise } from "./screens/Franchise";
import { Component as PrivacyPolicy } from "./screens/PrivacyPolicy";
import { Component as Offer } from "./screens/Offer";
import { Component as Policy } from "./screens/Policy";
// import { Component as Rules } from "./screens/Rules";
// import { Component as Shop } from "./screens/Shop";
import { Component as ShopOffer } from "./screens/ShopOffer";
import { Component as ShopClosed } from "./screens/ShopClosed";
// import { Component as ShopStatus } from "./screens/ShopStatus";
import { Component as Menu } from "./components/Menu";
import { Component as Consent } from "./components/Consent";
import { withAxios } from "react-axios";

export default withAxios(
  class App extends Component {
    constructor(props) {
      super(props);
      // ReactPixel.init("1188575298184533");
      // ReactPixel.pageView();
      this.props.axios.defaults.baseURL =
        process.env.REACT_APP_API_URL_SPB || "";
      //axios.defaults.headers.common['Authorization']
    }
    render() {
      return (
        <Router>
          <Menu />
          <Switch>
            <Route exact path="/" component={Index} />
            <Route exact path="/cities/:city" component={City} />
            <Route exact path="/cities/:city/:place" component={Place} />
            <Route exact path="/contacts" component={Contacts} />
            <Route exact path="/catering" component={Catering} />
            <Route exact path="/franchise" component={Franchise} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/offer" component={Offer} />
            <Route
              exact
              path="/offer"
              component={() => <Offer city={"spb"} />}
            />
            <Route
              exact
              path="/offer/spb"
              component={() => <Offer city={"spb"} />}
            />
            <Route
              exact
              path="/offer/moscow"
              component={() => <Offer city={"moscow"} />}
            />
            <Route
              exact
              path="/policy"
              component={() => <Policy city={"spb"} />}
            />
            <Route
              exact
              path="/policy/spb"
              component={() => <Policy city={"spb"} />}
            />
            <Route
              exact
              path="/policy/moscow"
              component={() => <Policy city={"moscow"} />}
            />
            {/*<Route
              exact
              path="/rules"
              component={() => <Rules city={"spb"} />}
            />
            <Route
              exact
              path="/rules/spb"
              component={() => <Rules city={"spb"} />}
            />
            <Route
              exact
              path="/rules/moscow"
              component={() => <Rules city={"moscow"} />}
            />*/}
            <Route exact path="/we-are-offering" component={() => <ShopOffer />} />
            <Route exact path="/shop" component={() => <ShopClosed />} />
            <Route exact path="/shop/moscow" component={() => <ShopClosed />} />
            <Route
              exact
              path="/spb/shop/:status"
              component={() => <ShopClosed />}
            />
            <Route
              exact
              path="/moscow/shop/:status"
              component={() => <ShopClosed />}
            />
            <Route
              exact
              path="/shop/:status"
              component={() => <ShopClosed />}
            />
            <Redirect to="/" />
          </Switch>
          <Consent />
        </Router>
      );
    }
  }
);
