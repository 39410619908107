const Ru = {
  common: {
    spb: 'Санкт-Петербург',
    moscow: 'Москва',
    catering: 'Кейтеринг',
    delivery: 'Доставка СПб',
    delivery_moscow: 'Доставка Москва',
    contacts: 'Контакты',
    franchise: 'Франшиза',
    food: 'Еда',
    alco: 'Алко',
    menu: 'Меню',
    izakaya: 'Изакая',
    policy: 'Политика конфиденциальности',
    offer: 'Публичная оферта',
  },
  cart: {
    add: 'В корзину',
    cart: 'Корзина',
    pieces: 'шт',
    total: 'Итого',
    proceed: 'Оформить заказ',
    offline: 'Сейчас доставка не работает',
    temporary_offline: 'Доставка временно не работает',
    working_hours: 'Часы работы: с {{from}} по {{to}}',
    form: {
      title: 'Оформление заказа',
      checkout: 'Подтвердить заказ',
      phone: 'Телефон',
      name: 'Имя',
      callback: 'Я хочу, чтобы мне перезвонили и подтвердили заказ по телефону',
      special_offer: 'Я хочу бесплатный презерватив в подарок',
      address: 'Улица, дом',
      apartment: 'Квартира',
      comment: 'Комментарий',
      cutlery: 'Количество приборов',
      promocode: 'Промокод',
      nothing_found: 'Ничего не найдено',
      errors: {
        required: 'Это поле обязательно',
        incorrect_format: 'Неправильный формат',
        must_greater: 'Заказ должен быть больше чем {{min}} ₽',
        not_found: 'Адрес не найден, либо мы не доставляем в эту зону',
      },
    },
  },
  franchise: {
    form: {
      name: 'Имя/Фамилия',
      phone: 'Телефон',
      email: 'Email',
      download: 'Скачать',
      downloading: 'Загружается...',
      errors: {
        required: 'Это поле обязательно',
        incorrect_format: 'Неправильный формат',
      },
    },
  },
  shop: {
    success: 'Спасибо за ваш заказ!',
    fail: 'Что-то пошло не так, попробуйте снова.',
    loading: 'Загружается... Пожалуйста подождите',
    spb: {
      rules:
        'Внимание! Перед оформлением заказа ознакомьтесь с <0>правилами и зоной доставки</0>. В случае вопросов звоните на номер <1>+7(812) 678 99 55</1>',
    },
    moscow: {
      rules:
        'Внимание! Перед оформлением заказа ознакомьтесь с <0>правилами и зоной доставки</0>. В случае вопросов звоните на номер <1>+7(495) 922-64-38</1>',
    },
  },
  city: {
    spb: {
      subzero: {
        name: 'Subzero',
        address: 'ул. Рубинштейна, 38',
      },
      subzero2: {
        name: 'Subzero II',
        address: 'ул. Академика Павлова, 5',
      },
      bar: {
        name: 'Subzero Bar',
        address: 'ул. Большая Зеленина, 19',
      },
      market: {
        name: 'Subzero Market',
        address: 'ул. Большой пр. В.О., 16',
      },
      market2: {
        name: 'Subzero Market II',
        address: 'ул. Решетникова, 12',
      },
    },
    moscow: {
      subzero: {
        name: 'Subzero',
        address: 'ул. Петровка, 30/7',
      },
    },
  },
  hours: {
    'sun-thu-12-00': 'Вск-Чтв 12:00 - 00:00',
    'fri-sat-12-02': 'Пят-Суб 12:00 - 02:00',
    'mon-sun-12-00': '12:00 - 00:00',
    'mon-sun-12-23': '12:00 - 23:00',
    'mon-sun-11-22-23': '11:00 - 22:00 (23:00)',
    special_mondays: 'первый и третий понедельник месяца мы открыты с 12:30',
  },
  texts: {
    'contacts-email': 'По вопросам сотрудничества',
    'follow-us': 'Следите за нами в соцсетях',
    catering: {
      text1: 'Кейтерингу Subzero по силам организация любого мероприятия!',
      text2: `Мы оказываем услуги кейтеринга и делаем это креативно, качественно, безопасно и,
        разумеется, очень вкусно.
        <br />
        У нас большой опыт обслуживания самых разных мероприятий: от камерных
        квартирников и загородных пикников для 10 друзей до свадеб, конференций и
        масштабных гала-ужинов с несколькими сотнями гостей. К каждому заказчику у нас
        индивидуальный подход и огромное желание сделать любой праздник
        незабываемым.`,
      text3: `Мы не просто готовим и доставляем еду, мы готовы сделать под ключ мероприятие
        любой сложности. Мы предоставляем услуги по оформлению и декору, сдаем в
        аренду посуду, столы и стулья, обеспечиваем слаженную работу персонала: поваров и
        официантов. В общем, мы позаботимся обо всем: вам останется только получать
        удовольствие от праздника.`,
      text4: `Цены обсуждаются индивидуально и полностью зависят от пожеланий гостя.`,
      text5: ``,
    },
    consent: {
      banner:
        'ООО Саб-Зиро <0>использует cookie</0> (файлы с данными о прошлых посещениях сайта) для персонализации сервисов и удобства пользователей. Саб-Зиро серьезно относится к защите персональных данных — ознакомьтесь с <0>условиями и принципами их обработки</0>. Вы можете запретить сохранение cookie в настройках своего браузера.',
      agree: 'Принять',
      page: {
        text1:
          'Согласие на обработку персональных данных для посетителей сайта',
        text2: `Продолжая работу на сайте я выражаю свое согласие ООО “Саб-Зиро” (адрес: 191002,
          г. Санкт-Петербург, пр-т Загородный д. 9 лит. А, пом. 29-Н офис 1) на автоматизированную
          обработку моих персональных данных (файлы cookie, сведения о действиях пользователя на сайте,
          сведения об оборудовании пользователя, дата и время сессии), в т.ч. с использованием
          метрических программ Яндекс.Метрика, Google Analytics, Firebas Google, Tune, Amplitude,
          Сегменто и т. д с совершением действий: сбор, запись, систематизация, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование,
          удаление, уничтожение, передача (предоставление, доступ), в том числе трансграничная,
          партнёрам Subzero, предоставляющим сервис по указанным метрическим программам. Обработка персональных
          данных осуществляется в целях улучшения работы сайта, совершенствования продуктов и услуг
          ресторана, определения предпочтений пользователя, предоставления целевой информации по
          продуктам и услугам Subzero и его партнёров.
          <br />
          Настоящее согласие действует с момента его предоставления и в течение всего периода
          использования сайта.
          <br />
          В случае отказа от обработки персональных данных метрическими программами я
          проинформирован о необходимости прекратить использование сайта или отключить файлы cookie
          в настройках браузера.
          <br />
          Subzero очень серьезно относится к вопросам конфиденциальности и безопасности информации.
          Защита ваших персональных данных — один из наших ключевых приоритетов.
          <br />
          Мы обрабатываем ваши персональные данные, собранные на законных основаниях и в рамках
          четко сформулированных целей, характерных для взаимодействия Subzero со всеми сторонами:
          <br />
          — клиентами, потенциальными клиентами, их родственниками или представителями;
          <br />
          — контрагентами и партнерами (как существующими, так и потенциальными);
          <br />
          — сотрудниками (включая их родственников) и соискателями.
          <br />
          Мы можем собирать ваши персональные данные, информацию о предпочтениях, совершенных
          действиях и транзакциях и т.п. при помощи веб-сайта и мобильных приложений Subzero для
          заранее определенных и законных целей.
          <br />
          Мы можем передавать ваши персональные данные, в том числе трансгранично, строго при
          соблюдении требований законодательства.
          <br />
          Мы уважаем ваши права и свободы, в частности, связанные с вопросами обработки ваших
          персональных данных.`,
      },
    },
    franchise: {
      download: 'Скачать',
      header1: `Открой ресторан Subzero<br />у себя в городе`,
      text1: 'Презентация франшизы и Финансовая модель',
      header2: 'Мы предлагаем различные варианты сотрудничества',
      text2:
        'Вы хотите открыть ресторан Subzero “с нуля” в вашем городе и участвовать в управлении рестораном',
      text3: `Вы собственник помещения и хотите открыть ресторан Subzero в своей локации`,
      text4:
        'Вы хотите инвестировать средства в сеть ресторанов Subzero для получения пассивного дохода',
      header3: 'Ключевые финансовые показатели ресторана Subzero',
      text5: 'Рентабельность',
      text6: `<strong>29%</strong><br />Еда`,
      text7: '<strong>40%</strong><br />Напитки, Алкоголь',
      text8: 'Средний чек<br /><strong>1500 руб.</strong>',
      text9: `Средний оборот ресторана в год<br /><strong>100 млн. руб.</strong>`,
      text10: 'Вложение в ресторан от<br /><strong>13 млн. руб.</strong>',
      text11: 'Окупаемость проекта<br /><strong>12-18 месяцев</strong>',
      text12: '*Усредненные данные на основе действующих ресторанов',
      header4: 'Мы понимаем как устроен ресторанный бизнес изнутри',
    },
  },
};

export default Ru;
