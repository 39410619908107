import React, { Component } from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "./style.sass";

class OfferScreen extends Component {
  render() {
    const city = this.props.city || "spb";

    return (
      <div className="offer-screen__holder">
        <div className="offer-screen__container">
          <div className="offer-screen__content">
            <h2 className="offer-screen__headers">Публичная оферта</h2>
            <div className="offer-screen__info">
              Публичная оферта о продаже товаров дистанционным способом
              (действует с 25 апреля 2020 года) 1. Термины и определения
              <br />
              1.1. В настоящей оферте, если из контекста не следует иное,
              нижеприведенные термины с заглавной буквы имеют следующие
              значения.
              <br />
              1.1.1. «Курьерская служба» — лица, оказывающие услуги по доставке
              Товаров от имени Продавца или сотрудники Продавца.
              <br />
              1.1.2. «Продавец» — юридическое лицо, созданное в соответствии с
              законодательством Российской Федерации и осуществляющее
              предпринимательскую деятельность по продаже Товаров, в том числе
              дистанционным способом. Полное фирменное наименование и место
              нахождения Продавца:{" "}
              {city === "spb"
                ? `ИП ЛУНИН ЭДУАРД АНДРЕЕВИЧ (ИНН 781393916163), г. Санкт-Петербург ул. Большая Посадская 10 квартира 18.`
                : `ООО “Сабзиро Столица” (ИНН 7707437060), г. Москва, ул. Петровка, д. 30/7`}
              <br />
              1.1.3. «Покупатель» — любое дееспособное лицо, намеренное заказать
              или приобрести, либо заказывающий, приобретающий или использующий
              Товары исключительно для личных, семейных, домашних или иных нужд,
              не связанных с осуществлением предпринимательской деятельности.
              <br />
              1.1.4. «Сайт» — subzerosushi.ru.
              <br />
              1.1.5. «Телефон» —{" "}
              {city === "spb" ? "+7 812 678 99 55" : "+7 495 922 64 38"}.
              <br />
              1.1.6. «Товары» — перечень продукции Продавца, представленный на
              Сайте. Товары могут отличаться от изображения на Сайте.
              <br />
              1.2. Для целей настоящей оферты термины, и определения в
              единственном числе относятся также и к терминам и определениям во
              множественном числе.
              <br />
              <br />
              2. Общие положения
              <br />
              2.1. Продавец и Покупатель гарантируют, что обладают необходимой
              право- и дееспособностью, а также всеми правами, необходимыми для
              заключения и исполнения договора розничной купли-продажи
              дистанционным способом.
              <br />
              2.2. Покупатель обязуется ознакомиться с условиями настоящей
              оферты, с информацией, указанной на Сайте в отношении Товаров, с
              условиями участия в Программе лояльности, Политикой
              Конфиденциальности, Пользовательским соглашением.
              <br />
              2.3. Продавец оставляет за собой право вносить изменения в
              настоящую оферту, в связи с чем Покупатель обязуется
              самостоятельно отслеживать наличие изменений в настоящей оферте.
              Редакции оферт, действовавших до даты, указанной в названии
              настоящей оферты, предоставляются по запросу.
              <br />
              2.4. При оформлении заказа Товаров Покупатель тем самым
              подтверждает, что он исполнил обязанности, предусмотренные пунктом
              2.2. настоящей оферты.
              <br />
              2.5. Договор розничной купли-продажи дистанционным способом
              считается заключенным с момента получения Продавцом сообщения о
              намерении Покупателя приобрести Товары.
              <br />
              2.6. Срок, в течение которого действует предложение о заключении
              договора розничной купли-продажи дистанционным способом, является
              бессрочным.
              <br />
              <br />
              3. Предмет Договора
              <br />
              3.1. Продавец продает Товары по ценам, представленным на Сайте
              Продавца, и доставляет Товар Собственной Курьерской службой, а
              Покупатель производит оплату и принимает Товары в соответствии с
              условиями настоящей оферты.
              <br />
              3.2. Право собственности на Товары переходит к Покупателю в момент
              приемки Товаров Покупателем.
              <br />
              <br />
              4. Уведомления Покупателя
              <br />
              4.1. Продавец вправе осуществлять рассылку уведомлений о
              проводимых акциях и рекламных материалов Пользователю.
              <br />
              4.2. Покупатель имеет право в любой момент отписаться от
              уведомлений, указанных в пункте 4.1. настоящей оферты. Для этого
              покупатель может сообщить о своем отказе на контактные данные,
              указанные на сайте продавца.
              <br />
              4.3. Продавец вправе совершать вызовы в адрес Пользователя в целях
              проведения маркетинговых исследований среди Покупателей и
              исследования предпочтений Покупателей; проведения розыгрышей
              призов среди Покупателей; проведения анализа оказываемых Продавцом
              услуг; урегулирования конфликтных ситуаций без получения от
              Пользователя согласия на совершение всех перечисленных выше
              действий.
              <br />
              4.4. Продавец вправе отправлять сервисные сообщения, информирующие
              Покупателя о совершенном им заказе, его составе, этапах его
              обработки и о готовности заказа; такие сервисные сообщения
              отправляются автоматически, не могут быть отклонены Покупателем,
              направлены на контроль качества оказания услуг Покупателю и его
              информирование о надлежащем исполнении Продавцом своих
              обязательств по договору купли-продажи дистанционным способом.
              <br />
              <br />
              5. Оформление заказа Товаров
              <br />
              5.1. Заказ Покупателя может быть оформлен по Телефону и/или
              посредством заполнения электронной формы заказа на Сайте.
              <br />
              5.2. Покупатель обязуется предоставить достоверные данные,
              необходимые и достаточные для исполнения договора розничной
              купли-продажи Товаров дистанционным способом.
              <br />
              5.3. В случае возникновения у Покупателя дополнительных вопросов,
              касающихся Товаров, перед оформлением Заказа Покупатель должен
              обратиться к Продавцу по Телефону для получения необходимой
              информации, в соответствии с режимом работы, указанным на Сайте.
              <br />
              5.4. Покупатель может заказать только те Товары, которые есть в
              наличии у Продавца на момент оформления заказа Товаров у Продавца.
              <br />
              5.5. Покупатель не имеет право изменить состав заказа.
              <br />
              5.6. При оформлении заказа по Телефону Продавец вправе
              осуществлять аудиозапись телефонного разговора с Пользователем,
              если указанные действия необходимы Продавцу для исполнения
              обязанностей, предусмотренных настоящей офертой, и оценки качества
              оказанных услуг, без получения от Пользователя согласия на
              совершение указанных действий.
              <br />
              5.7. При оформлении заказа посредством заполнения электронной
              формы заказа на Сайте Покупатель создает учетную запись в
              соответствии с Пользовательским соглашением, указанным на Сайте.
              <br />
              5.8. Если у Продавца отсутствует необходимое количество или
              ассортимент заказанных Покупателем Товаров, Продавец информирует
              об этом Покупателя по телефону в течение 30 минут после получения
              заказа Товаров от Покупателя. Покупатель вправе согласиться
              принять Товары в ином количестве или ассортименте, либо
              аннулировать свой заказ Товаров. в случае неполучения ответа
              Покупателя Продавец вправе аннулировать Заказ Покупателя в полном
              объеме.
              <br />
              5.9. Продавец вправе временно приостановить прием заказов Товаров
              в случае технических неполадок, не позволяющих принять заказ
              Товаров, или отсутствия ингредиентов, необходимых для изготовления
              Товаров.
              <br />
              <br />
              6. Доставка Товаров
              <br />
              6.1. Доставка Товаров осуществляется Собственной Курьерской
              службой по фактическому адресу, указанному Покупателем, при
              оформлении заказа или выборкой Товаров Покупателем (самовывозом).
              <br />
              6.2. Доставка Товаров в пределах зоны доставки осуществляется в
              соответствии с режимом работы Продавца, указанным на Сайте.
              <br />
              6.3. Доставка Товаров по адресам, не входящим в зону доставки, не
              осуществляется.
              <br />
              6.4. Срок доставки заказа Товаров озвучивается оператором при
              принятии заказа. В случае нарушения установленных сроков доставки
              Товаров, в силу обстоятельств непреодолимой силы («форс-мажор») в
              соответствии с Разделом 9 настоящей оферты Продавец не считается
              просрочившим доставку.
              <br />
              6.5. Доставка осуществляется при условии заказа Товаров на сумму
              минимального заказа. Сумма минимального заказа определяется
              Продавцом в одностороннем порядке и указывается на Сайте.
              <br />
              6.6. Продавец вправе отказаться от исполнения договора розничной
              купли-продажи дистанционным способом в том случае, если Курьерская
              служба по прибытии к фактическому адресу доставки Покупателя, не
              может осуществить фактическую передачу доставленных Товаров по
              вине Покупателя (Покупатель в течение 15 минут не отвечает на
              телефонный вызов или не открывает дверь Курьерской службе).
              <br />
              <br />
              7. Оплата Товаров
              <br />
              7.1. Цены на Товары определяются Продавцом в одностороннем порядке
              и указываются на Сайте. Цена на заказанный Покупателем Товар
              изменению не подлежит.
              <br />
              7.2. Оплата осуществляется по факту получения Товаров наличными
              денежными средствами либо путем оплаты по банковской карте онлайн
              <br />
              7.3. Выдача либо направление Покупателю кассового чека:
              <br />
              7.3.1.На бумажном носителе, в случае совершения заказа Товаров
              через Телефон и оплаты в порядке, предусмотренном пунктом 7.2.
              настоящей оферты, если Покупатель в момент совершения заказа не
              сообщил Продавцу о желании получить кассовый чек в электронном
              виде и не выполнил условия, предусмотренные пунктом 7.4.2
              настоящей оферты.
              <br />
              7.4. Условия направления кассового чека в электронном виде:
              <br />
              7.4.1. Внесение Покупателем данных об адресе электронной почты в
              личную учетную запись на Сайте при регистрации в порядке,
              предусмотренном Пользовательским соглашением;
              <br />
              7.4.2. Внесение Покупателем данных об адресе электронной почты в
              личную учетную запись на Сайте при регистрации в порядке,
              предусмотренном Пользовательским соглашением, и при условии
              совершения Покупателем заказа Товаров с номера телефона, который
              идентичен номеру телефона, внесенному Покупателем в личной учетной
              записи на Сайте в порядке, предусмотренном Пользовательским
              соглашением.
              <br />
              <br />
              8. Возврат и обмен Товаров
              <br />
              8.1. Возврат Товара надлежащего качества не допускается.
              <br />
              8.2. Продавец обязан передать Покупателю Товары, которые полностью
              соответствуют его заказу, качество которых соответствует
              информации, представленной Покупателю при заключении договора
              розничной купли-продажи дистанционным способом, а также
              информации, доведенной до его сведения при передаче Товаров (на
              этикетке или вкладыше, прикрепленных к Товарам или их упаковке,
              либо другими способами, предусмотренными для отдельных видов
              Товаров).
              <br />
              8.3. При получении Товаров Покупатель проверяет соответствие
              полученных Товаров своему заказу, комплектность и отсутствие
              претензий к внешнему виду доставленных Товаров в течение 10
              (Десяти) минут после получения Товаров.
              <br />
              8.4. В случае получения некачественных Товаров, либо
              несоответствия полученных Товаров заказанному (отсутствие
              заявленных ингредиентов в Товаре), Покупатель вправе потребовать
              замены таких Товаров Товарами надлежащего качества сразу в течение
              5 (Пяти) минут после получения Товаров.
              <br />
              8.5. В случае невыполнения порядка предъявления претензии
              Покупателя по внешнему виду Товаров и их комплектности,
              предусмотренных пунктом 8.3, пунктом 8.4 настоящей оферты,
              претензии не принимаются.
              <br />
              <br />
              9. Форс-мажор
              <br />
              9.1. Любая из Сторон освобождается от ответственности за полное
              или частичное неисполнение своих обязательств по настоящей оферте,
              если это неисполнение было вызвано обстоятельствами непреодолимой
              силы. Обстоятельства непреодолимой силы означают чрезвычайные
              события и обстоятельства, которые Стороны не могли ни предвидеть,
              ни предотвратить разумными средствами. Такие чрезвычайные события
              или обстоятельства включают в себя, в частности: забастовки,
              наводнения, пожары, землетрясения и иные стихийные бедствия,
              войны, военные действия и так далее.
              <br />
              <br />
              10. Ответственность Сторон публичной оферты
              <br />
              10.1. Продавец не несет ответственности за ущерб, причиненный
              Покупателю вследствие ненадлежащего использования им Товаров,
              приобретённых у Продавца.
              <br />
              10.2. Продавец не отвечает за убытки Покупателя, возникшие в
              результате неправильного заполнения Заказа, в том числе
              неправильного указания своих данных.
              <br />
              10.3. В случае злоупотребления Покупателем правами потребителя,
              включая, но не ограничиваясь, если Покупатель отказался от приемки
              и/или оплаты заказанного и доставленного Товара, Продавец вправе
              реализовать свое право на самозащиту, а именно: не принимать от
              него будущие Заказы.
              <br />
              10.4. В случае одностороннего отказа Продавца от исполнения
              договора розничной купли-продажи дистанционным способом в
              соответствии с пунктом 6.6. настоящей публичной оферты, Продавец
              вправе реализовать свое право на самозащиту, взыскать убытки с
              Покупателя. в случае, если Покупатель оплатил Товар до его
              доставки, оплаченная сумма не возвращается.
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(OfferScreen));
