import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Component as Place } from './../../components/Place';
import './style.sass';

class CityScreen extends Component {
  state = {
    cities: {
      'saint-petersburg': ['subzero', 'subzero-2', 'bar', 'market', 'market2'],
      moscow: ['subzero'],
    },
    city: '',
  };

  setCity() {
    const { match } = this.props;
    if (match.params.city && this.props.city !== match.params.city) {
      this.setState({
        city: match.params.city,
      });
    }
  }

  componentDidMount() {
    this.setCity();
  }

  componentDidUpdate() {
    const { match } = this.props;
    if (match.params.city && this.state.city !== match.params.city) {
      this.setCity();
    }
  }

  render() {
    if (!this.state.city) return '';

    const places = this.state.cities[this.state.city] || [];

    return (
      <div className='city-screen__holder'>
        <video
          className='city-screen__video'
          poster='https://subzerosushi.ru/static/images/city-poster.jpg'
          autoPlay={true}
          muted
          autobuffer='true'
          loop
          playsInline={true}
        >
          <source
            src='https://subzerosushi.ru/static/videos/pageanimation.mp4'
            type='video/mp4'
          />
          <source
            src='https://subzerosushi.ru/static/videos/pageanimation.webm'
            type='video/webm'
          />
          <source
            src='https://subzerosushi.ru/static/videos/pageanimation.ogv'
            type='video/ogg'
          />
        </video>
        <div className='city-screen__container'>
          <div className='city-screen__list'>
            {places.map((place) => (
              <Place
                key={`${this.props.city}:${place}`}
                city={this.state.city}
                place={place}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CityScreen);
