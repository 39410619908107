import React, { Component } from "react";
import { Provider } from "react-redux";
import { withTranslation } from "react-i18next";
import categoryStore from "./../../redux/categoryStore";
import "./style.sass";

class CategoryMenu extends Component {
  state = {
    current: null,
    ref: null,
  };

  constructor(props) {
    super(props);
    this.categoryChanged = this.categoryChanged.bind(this);
    this.onCategoryClick = this.onCategoryClick.bind(this);
  }

  componentDidMount() {
    categoryStore.subscribe(this.categoryChanged);
    this.categoryChanged();
  }

  componentWillUnmount() {
    if (categoryStore !== undefined && categoryStore.unsubscribe !== undefined)
      categoryStore.unsubscribe();
  }

  categoryChanged() {
    this.setState({ ...categoryStore.getState() });
    if (!this.state?.current) return;

    document.querySelector(
      ".category-nav"
    ).scrollLeft = document.getElementById(
      `category-menu-${this.state.current.id}`
    ).offsetLeft;
  }

  onCategoryClick(category) {
    window.scrollTo({
      top:
        document.querySelector(`#category-${category.id}`).offsetTop -
        document.querySelector(".category-nav").getBoundingClientRect().height -
        document.querySelector(".navigation__container").getBoundingClientRect()
          .height,
      behavior: "smooth",
    });
  }

  render() {
    const categories = this.props.categories || [];

    if (categories.length === 0) return null;

    // TODO: clean-up name (i18n)

    return (
      <Provider store={categoryStore}>
        <nav className="category-nav">
          <div className="category-nav__holder">
            {categories.map((category) => (
              <div
                id={`category-menu-${category.id}`}
                className={
                  this.state.current && this.state.current.id === category.id
                    ? "category-nav__item category-nav__item--active"
                    : "category-nav__item"
                }
                key={category.id}
                onClick={() => this.onCategoryClick(category)}
              >
                {this.props.i18n.language === "en"
                  ? category.name
                  : category.name_ru}
              </div>
            ))}
          </div>
        </nav>
      </Provider>
    );
  }
}

export default withTranslation()(CategoryMenu);
