import React, { Component } from "react";
import { withAxios } from "react-axios";
import { Trans, withTranslation } from "react-i18next";
import { Form, Field, FormSpy } from "react-final-form";
import InputMask from "react-input-mask";
import "./style.sass";

class FranchiseForm extends Component {
  state = {
    isOpen: false,
    isValid: false,
    isLoading: false,
    form: {},
  };

  componentDidUpdate(prev) {
    if (this.state.isOpen !== this.props.open) {
      this.setState({ isOpen: this.props.open });
    }
    this.changeBodyClass();
  }

  toggleForm = () => {
    this.changeBodyClass();
    this.setState({
      isOpen: !this.state.isOpen,
    });
    this.props.toggle();
  };

  changeBodyClass = () => {
    if (this.state.isOpen) {
      document.body.classList.add("body--open");
    } else {
      document.body.classList.remove("body--open");
    }
  };

  validate = (values) => {
    const errors = {};
    ["name", "email", "phone"].forEach((field) => {
      if (!values[field]) {
        errors[field] = errors[field] || [];
        errors[field].push(this.props.t("franchise.form.errors.required"));
      }
    });
    if (values.phone && !/\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/.test(values.phone)) {
      errors.phone = errors.phone || [];
      errors.phone.push(this.props.t("franchise.form.errors.incorrect_format"));
    }
    if (
      values.email &&
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        values.email
      )
    ) {
      errors.email = errors.email || [];
      errors.email.push(
        this.props.t("franchise.form.errors.incorrect_format1")
      );
    }
    if (Object.keys(errors).length === 0) {
      this.setState({
        isValid: true,
      });
    }
    return errors;
  };

  onSubmit = () => {
    this.setState({ isLoading: true });
    this.props.axios
      .post(
        "/franchise",
        {
          ...this.state.form,
        },
        { responseType: "blob" }
      )
      .then((result) => {
        console.log(result);
        let fileName = "franchise.pdf";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([result.data], {
              type: "application/pdf",
            }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([result.data], {
              type: "application/pdf",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.setState({ form: {} });
        }
        this.toggleForm();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };

  onChange = (values) => {
    this.setState({
      form: values.values,
    });
  };

  render() {
    if (!this.state.isOpen) return null;

    let downloadText = this.state.isLoading
      ? this.props.t("franchise.form.downloading")
      : this.props.t("franchise.form.download");

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={this.state.form}
        validate={this.validate}
        render={({ handleSubmit }) => (
          <div className="franchise-form__holder">
            <form onSubmit={handleSubmit} className="franchise-form">
              <div className="franchise-form__close" onClick={this.toggleForm}>
                &times;
              </div>
              <Field name="name" component="input" required>
                {({ input, meta }) => (
                  <label>
                    <span className="franchise-form__required">
                      <Trans i18nKey="franchise.form.name" />
                    </span>
                    <input {...input} type="text" />
                    {meta.error && meta.touched && (
                      <span className="franchise-form__error">
                        {meta.error}
                      </span>
                    )}
                  </label>
                )}
              </Field>
              <Field name="phone" component="input" required>
                {({ input, meta }) => (
                  <label>
                    <span className="franchise-form__required">
                      <Trans i18nKey="franchise.form.phone" />
                    </span>
                    <InputMask
                      mask="+7 (999) 999-99-99"
                      {...input}
                      onChange={input.onChange}
                      name={input.name}
                      value={input.value}
                    />

                    {meta.error && meta.touched && (
                      <span className="franchise-form__error">
                        {meta.error}
                      </span>
                    )}
                  </label>
                )}
              </Field>
              <Field name="email" component="input" required>
                {({ input, meta }) => (
                  <label>
                    <span className="franchise-form__required">
                      <Trans i18nKey="franchise.form.email" />
                    </span>
                    <input {...input} type="email" />
                    {meta.error && meta.touched && (
                      <span className="franchise-form__error">
                        {meta.error}
                      </span>
                    )}
                  </label>
                )}
              </Field>
              <input
                type="submit"
                value={downloadText}
                disabled={!this.state.isValid || this.state.isLoading}
              />
              <FormSpy
                subscription={{ values: true, valid: true }}
                onChange={this.onChange}
              />
            </form>
          </div>
        )}
      />
    );
  }
}

export default withTranslation()(withAxios(FranchiseForm));
