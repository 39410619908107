import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import MenuFrame from "./MenuFrame.svg";
import MenuDiv from "./MenuDiv.svg";
import "./style.sass";

class PlaceInfo extends Component {
  phone(data) {
    if (!data?.phone) return null;
    return (
      <a
        className="place-info__phone"
        href={`tel:${data.phone.replace(/ /g, "")}`}
      >
        {data.phone}
      </a>
    );
  }

  openHours(data) {
    if (!data?.open) return null;
    return (
      <div className="place-info__open-hours">
        {data.open.map((open, index) => (
          <div key={index}>
            <Trans i18nKey={open} />
          </div>
        ))}
      </div>
    );
  }

  address(data) {
    if (!data?.address) return null;

    return (
      <address>
        <Trans i18nKey={data.address} />
      </address>
    );
  }

  menu(itemsAll) {
    if (!itemsAll) return null;
    let items = JSON.parse(JSON.stringify(itemsAll));

    if (Array.isArray(items)) {
      return items.map((item) => {
        return this.menu(item);
      });
    }

    const keys = Object.keys(items);

    keys.map((key) => {
      if (typeof items[key] === "object") {
        items[key] = items[key][this.props.i18n.language];
      }
      return items[key];
    });

    let divider, second;
    if (keys.length === 2) {
      divider = (
        <div
          className="place-info__menu-divider"
          style={{ backgroundImage: `url(${MenuDiv})` }}
        ></div>
      );
      second = (
        <a href={items[keys[1]]} target="_blank" rel="noopener noreferrer">
          <Trans i18nKey={`common.${keys[1]}`}>{keys[1]}</Trans>
        </a>
      );
    } else {
      divider = null;
    }

    return (
      <div
        className="place-info__menu-links"
        style={{ backgroundImage: `url(${MenuFrame})` }}
        key={btoa(JSON.stringify(items))}
      >
        <a href={items[keys[0]]} target="_blank" rel="noopener noreferrer">
          <Trans i18nKey={`common.${keys[0]}`}>{keys[0]}</Trans>
        </a>
        {divider}
        {second}
      </div>
    );
  }

  render() {
    const data = this.props.data;

    return (
      <div className="place-info">
        <h3>
          <Trans i18nKey={data.name} />
        </h3>
        <div className="place-info__address-phone">
          {this.address(data)}
          {this.phone(data)}
        </div>
        {this.openHours(data)}
        {this.menu(data?.menu)}
      </div>
    );
  }
}

export default withTranslation()(PlaceInfo);
