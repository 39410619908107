import React, { Component } from "react";
import { Provider } from "react-redux";
import cartStore from "./../../redux/cartStore";
import { ADD_GOOD } from "./../../redux/actionTypes";
import { ReactComponent as Check } from "./Check.svg";
import { Trans, withTranslation } from "react-i18next";
import "./style.sass";

class Good extends Component {
  state = {
    added: false,
    timeout: null,
  };

  constructor(props) {
    super(props);
    this.addToCard = this.addToCard.bind(this);
  }

  addToCard() {
    const good = this.props.good || {};
    cartStore.dispatch({
      type: ADD_GOOD,
      payload: good,
      city: this.props.city,
    });
    if (this.state.timeout === null) {
      clearTimeout(this.state.timeout);
      this.setState({
        added: true,
        timeout: setTimeout(() => {
          clearTimeout(this.state.timeout);
          this.setState({ added: false, timeout: null });
        }, 2000),
      });
    }
  }

  render() {
    const good = this.props.good || {};

    good.images = good.images || [];
    if (good.images.length === 0) {
      good.images = [{ url: "" }];
    }

    // TODO: clean-up name (i18n)

    return (
      <Provider store={cartStore}>
        <div className="good">
          <div className="good__name">
            {this.props.i18n.language === "en" ? good.name : good.name_ru}
          </div>
          <img
            src={good.images[0].url}
            alt={good.name}
            className="good__image"
          />
          {/* <div className="good__price">{good.price} ₽</div> */}
          {/*
          <div className="good__cart" onClick={this.addToCard}>
            <Trans i18nKey="cart.add" />
            <div
              className={
                this.state.added
                  ? "good__cart-icon good__cart-icon--show"
                  : "good__cart-icon"
              }
            >
              <Check />
            </div>
          </div>
          */}
        </div>
      </Provider>
    );
  }
}

export default withTranslation()(Good);
