import React, { Component } from "react";
import { Component as PlaceInfo } from "./../PlaceInfo";
import { Component as PlacePhoto } from "./../PlacePhoto";
import { Component as PlaceMap } from "./../PlaceMap";
import { ReactComponent as GalleryClose } from "./Close.svg";
import Cities from "./../../utils/Cities";
import ImageGallery from "react-image-gallery";
import "./style.sass";

export default class Place extends Component {
  state = {
    cities: Cities,
    isGalleryOpen: false,
  };

  toggleGallery() {
    if (!this.state.isGalleryOpen) {
      document.body.classList.add("body--open");
    } else {
      document.body.classList.remove("body--open");
    }
    this.setState({
      isGalleryOpen: !this.state.isGalleryOpen,
    });
  }

  gallery(data) {
    const gallery = data.photos.gallery;

    if (this.props.showMap && data.map) return null;

    if (!gallery || Object.entries(gallery).length === 0) return null;

    return (
      <div
        className={
          this.state.isGalleryOpen
            ? "place__gallery place__gallery--open"
            : "place__gallery"
        }
      >
        <div
          className="place__gallery-close"
          onClick={() => this.toggleGallery()}
        >
          <GalleryClose />
        </div>
        <ImageGallery
          items={gallery.map((image) => {
            return {
              original: image,
              thumbnail: image,
            };
          })}
          showNav={false}
          showFullscreenButton={false}
          showPlayButton={false}
        />
      </div>
    );
  }

  photosOrMap = (data) => {
    if (this.props.showMap && data.map) return <PlaceMap map={data.map} />;

    return (
      <PlacePhoto
        photos={data.photos}
        onShowGallery={() => this.toggleGallery()}
      />
    );
  };

  render() {
    // TODO: MUST receive from backend (obviously)
    const data =
      (this.state.cities[this.props.city] || [])[this.props.place] || {};

    if (Object.entries(data).length === 0) return null;

    return (
      <div className="place">
        <PlaceInfo data={data} />
        {this.photosOrMap(data)}
        {this.gallery(data)}
      </div>
    );
  }
}
