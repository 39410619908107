import React, { Component } from "react";
import { withRouter } from "react-router";
import { Component as Place } from "./../../components/Place";
import "./style.sass";

class PlaceScreen extends Component {
  state = {
    city: "",
    place: "",
  };

  setCity() {
    if (this.state.city !== "" && this.state.place !== "") return;
    if (
      this.props.match.params.city &&
      (this.props.city !== this.props.match.params.city ||
        this.state.place !== this.props.match.params.place)
    ) {
      this.setState({
        city: this.props.match.params.city,
        place: this.props.match.params.place,
      });
    }
  }

  updateCity() {
    console.log(this.state, this.props.match.params.city, this.props.match.params.place)
    if (
      this.props.match.params.city &&
      (this.state.city !== this.props.match.params.city ||
        this.state.place !== this.props.match.params.place)
    ) {
      console.log('2222', this.state.city !== this.props.match.params.city, this.state.place !== this.props.match.params.place)
      this.setState({
        city: this.props.match.params.city,
        place: this.props.match.params.place,
      });
    }
  }

  componentDidMount() {
    this.setCity();
  }

  componentDidUpdate() {
    this.updateCity();
  }

  render() {
    return (
      <div className="place-screen__container">
        <div className="place-screen__holder">
          <Place city={this.state.city} place={this.state.place} />
        </div>
      </div>
    );
  }
}

export default withRouter(PlaceScreen);
