// TODO: find out
import { REDUCER_VERSION } from "./reducers/cartReducer";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;

    const state = JSON.parse(serializedState);
    if (state?.cartReducer?.version !== REDUCER_VERSION) {
      localStorage.removeItem("state");
      return undefined;
    }
    return state;
  } catch (error) {
    return undefined;
  }
};

export function saveState({ getState }) {
  return (next) => (action) => {
    const returnValue = next(action);

    try {
      const serializedState = JSON.stringify(getState());
      localStorage.setItem("state", serializedState);
    } catch (error) {}
    return returnValue;
  };
}
