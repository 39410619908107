import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { loadState, saveState } from "./persistedState";

const persistedState = loadState();

export default createStore(
  rootReducer,
  persistedState,
  applyMiddleware(saveState)
);
