const En = {
  common: {
    spb: "Saint Petersburg",
    moscow: "Moscow",
    catering: "Catering",
    delivery: "Delivery Spb",
    delivery_moscow: "Delivery Moscow",
    contacts: "Contacts",
    franchise: "Franchise",
    food: "Food",
    alco: "Alco",
    menu: "Menu",
    policy: "Privacy policy",
    offer: "Public offer",
  },
  cart: {
    add: "Add to cart",
    cart: "Cart",
    pieces: "pc",
    total: "Subtotal",
    proceed: "Proceed to checkout",
    offline: "Sorry, we are closed",
    temporary_offline: "Sorry, we are currently closed",
    working_hours: "Our working hours: from {{from}} to {{to}}",
    form: {
      title: "Checkout",
      checkout: "Checkout",
      phone: "Phone",
      name: "Name",
      callback: "I would like to have a callback and confirm the order",
      special_offer: "I would like to have free condom",
      address: "Street, house",
      apartment: "Apartment",
      comment: "Comment",
      cutlery: "Number of cutlery",
      promocode: "Promotional code",
      nothing_found: "Nothing found",
      errors: {
        required: "This field is required",
        incorrect_format: "This is incorrect format",
        must_greater: "Your order must be greater than {{min}} ₽",
        not_found: "Address not found or we are not delivering on this zone",
      },
    },
  },
  franchise: {
    form: {
      name: "Name/Surname",
      phone: "Phone",
      email: "Email",
      download: "Download",
      downloading: "Downloading...",
      errors: {
        required: "This field is required",
        incorrect_format: "This is incorrect format",
      },
    },
  },
  shop: {
    success: "Thank you for you order!",
    fail: "Something goes wrong, please try again.",
    rules:
      "Attention! Please read the information about <0>delivery rules and shipping area</0> before placing your order. Please call <1>+7(812) 678 99 55</1> if you still have any questions.",
    loading: "Loading... Please wait",
  },
  city: {
    spb: {
      subzero: {
        name: "Subzero",
        address: "Rubinshteyna, 38",
      },
      subzero2: {
        name: "Subzero II",
        address: "Akademika Pavlova, 5",
      },
      bar: {
        name: "Subzero Bar",
        address: "Bolshaya Zelenina, 19",
      },
      market: {
        name: "Subzero Market",
        address: "Bolshoy prospekt, VO, 16",
      },
      market2: {
        name: 'Subzero Market II',
        address: 'Reshetnikova, 12',
      },
    },
    moscow: {
      subzero: {
        name: "Subzero",
        address: "Petrovka, 30/7",
      },
    },
  },
  hours: {
    "sun-thu-12-00": "Sun-Thu 12:00 - 00:00",
    "fri-sat-12-02": "Fri-Sat 12:00 - 02:00",
    "mon-sun-12-00": "Mon-Sun 12:00 - 00:00",
    "mon-sun-12-23": "Mon-Sun 12:00 - 23:00",
    "mon-sun-11-22-23": "11:00 - 22:00 (23:00)",
    special_mondays:
      "on the first and third Monday of the month we are open from 12:30",
  },
  texts: {
    "contacts-email": "For cooperation please mail",
    catering: {
      text1:
        "It’s not only you who can visit Subzero, but Subzero can also come to your place.",
      text2: `Subzero catering team is a great way to surprise and please your
        guests.
        <br />
        Our restaurant has everything to organize any kind of event from
        small private parties and country picnics for ten to weddings,
        conferences and and large-scale gala dinners for several
        hundreds of guests.`,
      text3: `Subzero uses  individual approach to every customer and tries to
        make your event unforgettable, by doing it  creatively, safe
        and, of course, delicious.
        <br />
        We specialize not only on cooking and delivering meals, but we
        are also ready to organise a turn-key event of any complexity.
        We provide decoration and arrangement services, rental of
        crockery, tables  and chairs and guarantee well-coordinated team
        work of our chefs and waiters.`,
      text4: `Pricing is always discussed individually and absolutely depends on
        guests’ wishes.`,
      text5: `Therefore, we’ll take care of everything, so the only thing you
        are supposed to do is to enjoy your party.`,
    },
    consent: {
      banner:
        "Ltd. SubZero <0>uses cookies</0> (files with data about previous visits to the site) for personalization of services and convenience of users. SubZero is serious about protecting personal data - read <0>the terms and principles of data processing</0>. You can prevent cookies from being stored in your browser settings.",
      agree: "Accept",
      page: {
        text1: "Data Processing Agreement for Website Users",
        text2: `Продолжая работу на сайте я выражаю свое согласие ООО “Саб-Зиро” (адрес: 191002,
          г. Санкт-Петербург, пр-т Загородный д. 9 лит. А, пом. 29-Н офис 1) на автоматизированную
          обработку моих персональных данных (файлы cookie, сведения о действиях пользователя на сайте,
          сведения об оборудовании пользователя, дата и время сессии), в т.ч. с использованием
          метрических программ Яндекс.Метрика, Google Analytics, Firebas Google, Tune, Amplitude,
          Сегменто и т. д с совершением действий: сбор, запись, систематизация, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование,
          удаление, уничтожение, передача (предоставление, доступ), в том числе трансграничная,
          партнёрам Subzero, предоставляющим сервис по указанным метрическим программам. Обработка персональных
          данных осуществляется в целях улучшения работы сайта, совершенствования продуктов и услуг
          ресторана, определения предпочтений пользователя, предоставления целевой информации по
          продуктам и услугам Subzero и его партнёров.
          <br />
          Настоящее согласие действует с момента его предоставления и в течение всего периода
          использования сайта.
          <br />
          В случае отказа от обработки персональных данных метрическими программами я
          проинформирован о необходимости прекратить использование сайта или отключить файлы cookie
          в настройках браузера.
          <br />
          Subzero очень серьезно относится к вопросам конфиденциальности и безопасности информации.
          Защита ваших персональных данных — один из наших ключевых приоритетов.
          <br />
          Мы обрабатываем ваши персональные данные, собранные на законных основаниях и в рамках
          четко сформулированных целей, характерных для взаимодействия Subzero со всеми сторонами:
          <br />
          — клиентами, потенциальными клиентами, их родственниками или представителями;
          <br />
          — контрагентами и партнерами (как существующими, так и потенциальными);
          <br />
          — сотрудниками (включая их родственников) и соискателями.
          <br />
          Мы можем собирать ваши персональные данные, информацию о предпочтениях, совершенных
          действиях и транзакциях и т.п. при помощи веб-сайта и мобильных приложений Subzero для
          заранее определенных и законных целей.
          <br />
          Мы можем передавать ваши персональные данные, в том числе трансгранично, строго при
          соблюдении требований законодательства.
          <br />
          Мы уважаем ваши права и свободы, в частности, связанные с вопросами обработки ваших
          персональных данных.`,
      },
    },
    franchise: {
      download: "Download",
      header1: "Open your own Subzero<br /> restaurant in your city",
      text1: "Download the franchise presentation and financial model",
      header2: "We offer different ways of collaboration",
      text2:
        "If you want to open the Subzero restaurant in your city and participate in the restaurant operation",
      text3:
        "If you own a property and are interested in opening the Subzero restaurant in your location",
      text4:
        "If you are interested in investing in the Subzero chain for a passive income",
      header3: "Key financial indicators of the Subzero restaurant",
      text5: "Profitability",
      text6: "<strong>29%</strong><br />Food",
      text7: "<strong>40%</strong><br />Drinks and Alcohol",
      text8: "Average bill<br /><strong>1500 rbl.</strong>",
      text9: "Average annual turnover<br /><strong>100 mln. rbl.</strong>",
      text10: "Investments - from<br /><strong>13 mln. rbl.</strong>",
      text11: "Payback period<br /><strong>12-18 месяцев</strong>",
      text12: "*Average data accordingly existing restaurants",
      header4: "We know the restaurant business from inside out",
    },
  },
};

export default En;
