import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { ReactComponent as Mark } from "./Mark.svg";
import "./style.sass";

const MarkComponent = () => (
  <div>
    <Mark />
  </div>
);

export default class PlaceMap extends Component {
  createMapOptions() {
    return {
      styles: [
        {
          stylers: [
            { saturation: -100 },
            { gamma: 0.8 },
            { lightness: -50 },
            { visibility: "on" },
          ],
        },
      ],
    };
  }

  render() {
    if (!this.props.map) return null;

    return (
      <div className="place-map">
        <GoogleMapReact
          options={this.createMapOptions}
          bootstrapURLKeys={{
            key: "AIzaSyC7JAu6-ftVF-IG_DaY0rSMM_0M8va_Huk",
          }}
          defaultCenter={this.props.map.center}
          defaultZoom={14}
          yesIWantToUseGoogleMapApiInternals={true}
          distanceToMouse={() => {}}
        >
          <MarkComponent
            lat={this.props.map.center[0]}
            lng={this.props.map.center[1]}
          />
        </GoogleMapReact>
      </div>
    );
  }
}
