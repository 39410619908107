import React, { Component } from "react";
import { Provider } from "react-redux";
import { Component as Good } from "./../Good";
import { withTranslation } from "react-i18next";
import categoryStore from "./../../redux/categoryStore";
import { CHANGE_CATEGORY } from "./../../redux/actionTypes";
import { InView } from "react-intersection-observer";
import "./style.sass";

class Category extends Component {
  state = {
    width: 0,
  };

  componentDidMount() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const category = this.props.category || {};

    // TODO: clean-up name (i18n)
    return (
      <Provider store={categoryStore}>
        <InView
          as="div"
          className="category"
          id={`category-${category.id}`}
          threshold={this.state.width > 1119 ? [0.4] : 0}
          onChange={(inView, _) => {
            if (inView) {
              categoryStore.dispatch({
                type: CHANGE_CATEGORY,
                payload: category,
              });
            }
          }}
        >
          <h3>
            {this.props.i18n.language === "en"
              ? category.name
              : category.name_ru}
          </h3>
          <div className="category__description">
            {this.props.i18n.language === "en"
              ? category.description
              : category.description_ru}
          </div>
          <div className="category__goods">
            {category.goods.map((good) => (
              <Good key={good.id} good={good} city={this.props.city} />
            ))}
          </div>
        </InView>
      </Provider>
    );
  }
}

export default withTranslation()(Category);
