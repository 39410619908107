import React, { Component } from "react";
import { withRouter } from "react-router";
import { Trans, withTranslation } from "react-i18next";
import "./style.sass";

class CateringScreen extends Component {
  render() {
    return (
      <div className="catering-screen__holder">
        <div className="catering-screen__container">
          <div className="catering-screen__content">
            <h2 className="catering-screen__headers">
              <Trans i18nKey="common.catering">Catering</Trans>
            </h2>
            <div className="catering-screen__info">
              <h2 className="catering-screen__headers">
                <Trans i18nKey="texts.catering.text1" />
              </h2>
            </div>
            <div className="catering-screen__text-holder">
              <p className="catering-screen__text-mr">
                <Trans i18nKey="texts.catering.text2" />
              </p>
              <img
                src="https://subzerosushi.ru/static/images/spb/catering/catering-002.jpg"
                alt=""
                className="catering-screen__img-2"
              />
            </div>
            <div className="catering-screen__text-holder">
              <img
                src="https://subzerosushi.ru/static/images/spb/catering/catering-001.jpg"
                alt=""
                className="catering-screen__img-1"
              />
              <img
                src="https://subzerosushi.ru/static/images/spb/catering/catering-003.jpg"
                alt=""
                className="catering-screen__img-1"
              />
              <p className="catering-screen__text-ml">
                <Trans i18nKey="texts.catering.text3" />
              </p>
            </div>
            <div className="catering-screen__info">
              <Trans i18nKey="texts.catering.text4" />
            </div>
          </div>
        </div>
        <img
          src="https://subzerosushi.ru/static/images/spb/catering/catering-004.jpg"
          alt=""
          className="catering-screen__img-full"
        />
        <div className="catering-screen__container">
          <div className="catering-screen__content">
            <div className="catering-screen__info">
              <Trans i18nKey="texts.catering.text5" />
            </div>
            <div className="catering-screen__text-holder">
              <img
                src="https://subzerosushi.ru/static/images/spb/catering/catering-005.jpg"
                alt=""
                className="catering-screen__img-1"
              />
              <img
                src="https://subzerosushi.ru/static/images/spb/catering/catering-006.jpg"
                alt=""
                className="catering-screen__img-2"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(CateringScreen));
