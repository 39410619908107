import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import './style.sass';

class IndexScreen extends Component {
  render() {
    return (
      <div className='index-screen__container'>
        <video
          className='index-screen__video'
          poster='https://subzerosushi.ru/static/images/poster.jpg'
          autoPlay={true}
          muted
          autobuffer='true'
          loop
          playsInline={true}
        >
          <source
            src='https://subzerosushi.ru/static/videos/openinganimation.mp4'
            type='video/mp4'
          />
          <source
            src='https://subzerosushi.ru/static/videos/openinganimation.webm'
            type='video/webm'
          />
          <source
            src='https://subzerosushi.ru/static/videos/openinganimation.ogv'
            type='video/ogg'
          />
        </video>
        <div className='index-screen__cities'>
          <div>
            <Link
              to={{
                pathname: '/cities/saint-petersburg',
                search: this.props.location.search,
              }}
            >
              <Trans i18nKey='common.spb'>Saint Petersburg</Trans>
            </Link>
          </div>
          <div>
            <Link
              to={{
                pathname: '/cities/moscow',
                search: this.props.location.search,
              }}
            >
              <Trans i18nKey='common.moscow'>Moscow</Trans>
            </Link>
          </div>
        </div>
        <div className='index-screen__links'>
          {/*<div>
            <Link
              to={{
                pathname: "/shop",
                search: this.props.location.search,
              }}
            >
              <Trans i18nKey="common.delivery">Delivery Spb</Trans>
            </Link>
          </div>
          <div>
            <Link
              to={{
                pathname: "/shop/moscow",
                search: this.props.location.search,
              }}
            >
              <Trans i18nKey="common.delivery_moscow">Delivery Moscow</Trans>
            </Link>
          </div>
          <div>
            <Link
              to={{
                pathname: "/franchise",
                search: this.props.location.search,
              }}
            >
              <Trans i18nKey="common.franchise">Franchise</Trans>
            </Link>
          </div>
          <div>
            <Link
              to={{
                pathname: '/catering',
                search: this.props.location.search,
              }}
            >
              <Trans i18nKey='common.catering'>Catering</Trans>
            </Link>
          </div>
          */}
          <div>
            <Link
              to={{
                pathname: '/contacts',
                search: this.props.location.search,
              }}
            >
              <Trans i18nKey='common.contacts'>Contacts</Trans>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(IndexScreen);
