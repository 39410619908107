import React, { Component } from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "./style.sass";

SwiperCore.use([Pagination]);

export default class PlacePhoto extends Component {
  showGallery = () => {
    this.props.onShowGallery();
  };

  showGalleryButton() {
    const gallery = this.props.photos.gallery;

    if (!gallery || Object.entries(gallery).length === 0) return null;

    return (
      <div className="place-photo__gallery-button" onClick={this.showGallery}>
        <div>
          Show
          <br />
          me more
        </div>
      </div>
    );
  }

  showSwiper() {
    const gallery = this.props.photos.gallery;

    if (!gallery || Object.entries(gallery).length === 0)
      return (
        <div className="place-photo--smartphone-visible">
          <img src={this.props.photos.main} alt="" />
        </div>
      );

    return (
      <Swiper
        spaceBetween={5}
        slidesPerView="auto"
        loop={true}
        pagination={{ clickable: true }}
      >
        {gallery.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={image} alt="" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  }

  render() {
    const photos = this.props.photos;
    const gallery = this.props.photos.gallery;
    const isNotGallery = !gallery || Object.entries(gallery).length === 0;

    if (!photos || Object.entries(photos).length === 0) return null;

    return (
      <div
        className={
          isNotGallery ? "place-photo" : "place-photo place-photo--gallery"
        }
        style={{ backgroundImage: `url(${photos.main})` }}
      >
        {this.showGalleryButton()}
        {this.showSwiper()}
      </div>
    );
  }
}
