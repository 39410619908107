import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import En from './i18n/en';
import Ru from './i18n/ru';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: { ...En },
      },
      ru: {
        translation: { ...Ru },
      },
    },
    supportedLngs: ['ru', 'en'],
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring'],
      caches: [],
    },
  });

export default i18next;
