import { CHANGE_CATEGORY } from "./../actionTypes";

const initialState = {
  current: null,
};

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CATEGORY: {
      return {
        ...state,
        current: action.payload,
      };
    }
    default:
      return state;
  }
}
