import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { Trans, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ReactComponent as Logo } from './Logo.svg';
import { ReactComponent as MenuButton } from './Button.svg';
import { ReactComponent as MenuClose } from './Close.svg';
// import { ReactComponent as Instagram } from "./Instagram.svg";
// import { ReactComponent as Facebook } from "./Facebook.svg";
import Delimiter from './Delimiter.svg';
import './style.sass';

class Menu extends Component {
  state = {
    isOpen: false,
  };

  toggleMenu = () => {
    if (!this.state.isOpen) {
      document.body.classList.add('body--open');
    } else {
      document.body.classList.remove('body--open');
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  changeLang = (newLang) => {
    const lang = i18next.language;
    if (newLang === lang) return;
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: newLang === 'en' ? '' : `?lng=${newLang}`,
    });
    i18next.changeLanguage(newLang);
  };

  render() {
    const lang = i18next.language;

    return (
      <div className='navigation__container'>
        <nav>
          <Link to='/' className='navigation__logo'>
            <Logo />
          </Link>
          <div className='navigation__lang-panel'>
            <div
              className={
                lang === 'en'
                  ? 'navigation__lang-button navigation__lang-button--active'
                  : 'navigation__lang-button'
              }
              onClick={() => {
                this.changeLang('en');
              }}
            >
              Eng
            </div>
            <div
              className={
                lang === 'ru'
                  ? 'navigation__lang-button navigation__lang-button--active'
                  : 'navigation__lang-button'
              }
              onClick={() => {
                this.changeLang('ru');
              }}
            >
              Рус
            </div>
          </div>
          <div className='navigation__button' onClick={this.toggleMenu}>
            <MenuButton />
          </div>
        </nav>
        <div
          className={
            this.state.isOpen
              ? 'navigation__menu navigation__menu--open'
              : 'navigation__menu'
          }
        >
          <div className='navigation__menu-container'>
            <div className='navigation__menu-close' onClick={this.toggleMenu}>
              <MenuClose />
            </div>
            <div className='navigation__menu-links'>
              <div className='navigation__menu-links-column'>
                <div className='navigation__menu-link-header'>
                  <Link
                    to={{
                      pathname: '/cities/saint-petersburg',
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey='common.spb'>Saint Petersburg</Trans>
                  </Link>
                </div>
                <div className='navigation__menu-link'>
                  <Link
                    to={{
                      pathname: '/cities/saint-petersburg/subzero',
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey='city.spb.subzero.name'>Subzero</Trans>
                  </Link>
                  <address>
                    <Trans i18nKey='city.spb.subzero.address'>
                      Rubinshteyna, 38
                    </Trans>
                  </address>
                </div>
                <div className='navigation__menu-link'>
                  <Link
                    to={{
                      pathname: '/cities/saint-petersburg/subzero-2',
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey='city.spb.subzero2.name'>Subzero II</Trans>
                  </Link>
                  <address>
                    <Trans i18nKey='city.spb.subzero2.address'>
                      Akademika Pavlova, 5
                    </Trans>
                  </address>
                </div>
                <div className='navigation__menu-link'>
                  <Link
                    to={{
                      pathname: '/cities/saint-petersburg/bar',
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey='city.spb.bar.name'>Subzero Bar</Trans>
                  </Link>
                  <address>
                    <Trans i18nKey='city.spb.bar.address'>
                      Bolshaya Zelenina, 19
                    </Trans>
                  </address>
                </div>
                <div className='navigation__menu-link'>
                  <Link
                    to={{
                      pathname: '/cities/saint-petersburg/market',
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey='city.spb.market.name'>Subzero Market</Trans>
                  </Link>
                  <address>
                    <Trans i18nKey='city.spb.market.address'>
                      Bolshoy prospekt, VO, 16
                    </Trans>
                  </address>
                </div>
                <div className='navigation__menu-link'>
                  <Link
                    to={{
                      pathname: '/cities/saint-petersburg/market2',
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey='city.spb.market2.name'>
                      Subzero Market
                    </Trans>
                  </Link>
                  <address>
                    <Trans i18nKey='city.spb.market2.address'>
                      Reshetnikova, 12
                    </Trans>
                  </address>
                </div>
                {/*<div className="navigation__menu-link">
                  <Link
                    to={{
                      pathname: "/shop",
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey="common.delivery">Delivery Spb</Trans>
                  </Link>
                </div>
                <div className="navigation__menu-link">
                  <Link
                    to={{
                      pathname: "/shop/moscow",
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey="common.delivery_moscow">
                      Delivery Moscow
                    </Trans>
                  </Link>
                </div>
                */}
                <div
                  className='navigation__menu-divider--smartphone-only'
                  style={{ backgroundImage: `url('${Delimiter}')` }}
                />
              </div>
              <div className='navigation__menu-links-column'>
                <div className='navigation__menu-link-header'>
                  <Link
                    to={{
                      pathname: '/cities/moscow',
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey='common.moscow'>Moscow</Trans>
                  </Link>
                </div>
                <div className='navigation__menu-link'>
                  <Link
                    to={{
                      pathname: '/cities/moscow/subzero',
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey='city.moscow.subzero.name'>Subzero</Trans>
                  </Link>
                  <address>
                    <Trans i18nKey='city.moscow.subzero.address'>
                      Petrovka, 30/7
                    </Trans>
                  </address>
                </div>
                <div
                  className='navigation__menu-divider--smartphone-only'
                  style={{ backgroundImage: `url('${Delimiter}')` }}
                />
              </div>
              <div className='navigation__menu-links-column navigation__menu-links-column--bottom'>
                {/*<div className='navigation__menu-link'>
                  <Link
                    to={{
                      pathname: '/catering',
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey='common.catering'>Catering</Trans>
                  </Link>
                </div>
                <div className='navigation__menu-link'>
                  <Link
                    to={{
                      pathname: '/franchise',
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey='common.franchise'>Franchise</Trans>
                  </Link>
                  </div>*/}
                <div className='navigation__menu-link'>
                  <Link
                    to={{
                      pathname: '/contacts',
                      search: this.props.location.search,
                    }}
                    onClick={this.toggleMenu}
                  >
                    <Trans i18nKey='common.contacts'>Contacts</Trans>
                  </Link>
                </div>
                <div
                  className='navigation__menu-divider--smartphone-only'
                  style={{ backgroundImage: `url('${Delimiter}')` }}
                />
                {/*
                <div className="navigation__menu-link-external">
                  <a
                    href="https://www.instagram.com/subzerosushi/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.toggleMenu}
                  >
                    <Instagram />
                  </a>
                  <a
                    href="https://www.facebook.com/subzeroru"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.toggleMenu}
                  >
                    <Facebook />
                  </a>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Menu));
